export const currenyFormatter = (value) => {
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    roundingMode: "halfExpand",
  }).format(value);
};

export const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);

export function formatDate(inputDate, version = 'long') {
  // Split the date string
  const parts = inputDate.split('-');
  const year = parts[0];
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Determine the ordinal suffix
  let ordinal = 'th';
  if (day === 1 || (day > 20 && day % 10 === 1)) {
      ordinal = 'st';
  } else if (day === 2 || (day > 20 && day % 10 === 2)) {
      ordinal = 'nd';
  } else if (day === 3 || (day > 20 && day % 10 === 3)) {
      ordinal = 'rd';
  }

  // Map month number to month name

  const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const short_months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  
  // Ensure the date is valid (this covers the leap years too)
  if (new Date(inputDate).toISOString().slice(0,10) !== inputDate) {
      throw new Error('Invalid date');
  }

  return `${day}${ordinal} ${version === 'long' ? months[month - 1] : short_months[month - 1]} ${year}`;
}

export function startOfDay(date) {
  return new Date(date.setHours(0, 0, 0, 0));
}

export function addNYears(dateInput, n) {
  let date = startOfDay(new Date(dateInput)); // Ensure the input is a Date object
  let originalDay = date.getDate();
  let originalMonth = date.getMonth();

  // Temporarily set date to March 1st to avoid leap year issues with setFullYear
  if (originalMonth === 1 && originalDay === 29) {
      date.setMonth(2);
      date.setDate(1);
  }

  date.setFullYear(date.getFullYear() + n);

  // If original date was February 29 and resulting year is a leap year
  if (originalMonth === 1 && originalDay === 29 && (date.getFullYear() % 4 === 0) && (date.getFullYear() % 100 !== 0 || date.getFullYear() % 400 === 0)) {
      date.setMonth(1);
      date.setDate(29);
  } else if (originalMonth === 1 && originalDay === 29) {
      date.setMonth(1);
      date.setDate(28);
  } else {
      date.setMonth(originalMonth);
      date.setDate(originalDay);
  }


  return startOfDay(date);
}

