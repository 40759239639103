import React, { useContext, useEffect, useState, Fragment } from "react";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import classes from "./ServiceInputs.module.css";
import InfoModal from "../../general/InfoModal";

const PartTimeServiceInput = (props) => {
  
  const { inputs, setInputs } = useContext(InputDataContext);
  const [partTimeService, setPartTimeService] = useState(
    inputs.partTimeService
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const [error, setError] = useState(null);
  const [show_modal, set_show_modal] = useState(false)

  function startOfDay(date) {
    let proper_date = new Date(date)
    return new Date(proper_date.setHours(0, 0, 0, 0));
  }

  const modalHandler = () => set_show_modal(false)

  const infoMessage = <Fragment> 
    <h1> Part-time working history</h1>
    <p> Please enter the start and end dates for any periods of part-time working. The ‘percentage’ should be the proportion of whole-time equivalent hours worked over the period in question (so if whole-time hours per week for the role is 40, and you worked 20 hours per week, the percentage is 50%)</p>
  </Fragment>

  const addService = () => {
    const newService = {
      from: startDate,
      to: endDate,
      proportion: percentage,
    };

    if (startDate >= endDate) {
      setError("Start date must be before end date.");
      return;
    }

    if(
      startOfDay(endDate) > startOfDay(new Date(inputs.parAge))
    ) {
      setError("You should only provide your part-time working pattern up to your partial retirement date (" + props.formatDate(inputs.parAge) + "),  " +
        "but your 'end date' is later than this. You can use the next question to specify your post-partial retirement part-time working pattern."      
      );
      return;
    }



    if (percentage < 0 || percentage > 100) {
      setError("Percentage must be between 0 and 100.");
      return;
    }

    const isOverlap = (service1, service2) => {
      const start1 = new Date(service1.from);
      const end1 = new Date(service1.to);
      const start2 = new Date(service2.from);
      const end2 = new Date(service2.to);

      return start1 <= end2 && start2 <= end1;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isOverlap(partTimeService[i], newService)) {
        setError("This entry overlaps with an existing part-time service.");
        return;
      }
    }

    const isChronalogical = (service1, service2) => {
      const start1 = new Date(service1.from);
      const start2 = new Date(service2.from);

      return start1 >= start2;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isChronalogical(partTimeService[i], newService)) {
        setError("Please enter services in chronalogoical order.");
        return;
      }
    }

    if(startOfDay(startDate) < startOfDay(new Date(inputs.djs))) {
      setError("Your start date cannot be before your joining date"); 
      //this is the last error we check for. So we know that if it occurs, it is the only error present
      return;
    }

    setPartTimeService([
      ...partTimeService,
      { from: startDate, to: endDate, proportion: percentage },
    ]);

    setStartDate("");
    setEndDate("");
    setPercentage("");
    setError(null);
  };
 
  const removeService = (index) => {
    setPartTimeService(partTimeService.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setInputs({ ...inputs, partTimeService: partTimeService });
  }, [partTimeService]);

  useEffect(() => {
    setPartTimeService(inputs.partTimeService);
  }, [inputs.partTimeService]);


  function sanitisePercentString(str) {
    // 1. Remove any character that is not an arabic numeral, a decimal point, or percentage
    let cleaned = str.replace(/[^0-9.%.]/g, '');

    // 2. Handle decimal points
    if (cleaned.charAt(0) === '.') { // Remove if the first character is a decimal point
        cleaned = cleaned.substring(1);
    }

    let firstDecimalPointIndex = cleaned.indexOf('.');
    if (firstDecimalPointIndex !== -1) {
        // Remove any subsequent decimal points
        cleaned = cleaned.substring(0, firstDecimalPointIndex + 1) +
                  cleaned.substring(firstDecimalPointIndex + 1).replace(/\./g, '');
    }

    // 3. Handle percentage signs
    if (cleaned.charAt(cleaned.length - 1) === '%') {
        cleaned = cleaned.substring(0, cleaned.length - 1).replace(/%/g, '') + '%';
    } else {
        cleaned = cleaned.replace(/%/g, '');
    }

    // 4. Ensure no more than two characters after the decimal point
    firstDecimalPointIndex = cleaned.indexOf('.');
    if (firstDecimalPointIndex !== -1 && (firstDecimalPointIndex + 3) < cleaned.length) {
        cleaned = cleaned.substring(0, firstDecimalPointIndex + 3) +
                  (cleaned.endsWith('%') ? '%' : '');
    }
    return cleaned;
  }
 
  function processPercentDelete(x, y) {
    // Check if y is the same as x with a '%' appended
    if (y === x + '%') {
        // Return y with its penultimate character removed
        return y.slice(0, -2) + y.slice(-1);
    }
    // If the condition is not met, return x
    return x;
}
  
// const [Percentage, set_Percentage] = useState('')
  return (
    <div className={classes.service_container}>
      {show_modal && <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />}
      <section> 
      <h3>Tell us about your part-time working history</h3>
      <button onClick = {() => set_show_modal(true)}> Help</button>
      </section>
      <p>
        If you have never served a period of part-time service then leave this
        blank or select 'No' for the preceding question.
      </p>
      <p>
        Please enter periods of part-time service in chronological order. You
        can provide anticipated future periods of part-time service as well as
        periods in the past.
      </p>
      <p>
        If you have had more than one period of part-time service, enter details
        for each period. The value in the 'Percentage' field should have a
        number between 0 and 100 to reflect the percentage of full time
        equivalent hours that you worked during the relevant period. For
        example, if you worked 28 hours per week when the full time equivalent
        was 35 hours per week, your part-time proportion would be 80%, in which
        case you should write '80' in the percentage field.
      </p>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className={classes.subContainer}>
        <div className={classes.inputContainer}>
          <div className={classes.label_input}>
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className={classes.label_input}>
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.label_input} 
          style = {{
            //'position' : 'relative'
          }}
          >
            <label>Percentage (%):</label>
            <input
              type="text"
              value={(percentage === 0 || percentage ) ? percentage + '%' : ''}
              onChange={(e) => {

                const old_val = percentage === '' ? '' : percentage + '%'
      
                var new_val = processPercentDelete(e.target.value, old_val).replace('%', '')
      
                new_val = sanitisePercentString(new_val)
      
                setPercentage(sanitisePercentString(new_val))
      
              }
      }
              min={0}
              step={1}
              max={99}
            />
            {//<p style = {{position : 'absolute', right: '30%', top: '30%'}}> % </p>
            }
          </div>
        </div>
        <button onClick={addService}>Add Service</button>
      </div>
      <table>
        <thead>
          <tr>
            <th className={classes.leftColumn}></th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Proportion</th>
            <th className={classes.rightColumn}></th>
          </tr>
        </thead>
        <tbody>
          {partTimeService.map((service, i) => (
            <tr key={i}>
              <td className={classes.leftColumn}>{i + 1}.</td>
              <td>{new Date(service.from).toLocaleDateString("en-GB")}</td>
              <td>{new Date(service.to).toLocaleDateString("en-GB")}</td>
              <td>{service.proportion}%</td>
              <td className={classes.rightColumn}>
                <button onClick={() => removeService(i)}>X</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartTimeServiceInput;
