import React from "react";
import NavBar from "./NavBar";
import gadLogo from "../../assets/images/gadLogo.png";
import params from "../../shared/utilities/global_parameters";
import classes from "./Header.module.css";

const Header = () => {
  return (
    <header className={classes.headerContainer}>
      <div className={classes.imgContainer}>
        {false && <img
          src={gadLogo}
          className={classes.img}
          alt="Government Actuary's Department Logo"
        />}
      </div>
      <div className={classes.mainHeader}>
        <h1 className={classes.pageTitle}>{params.app_name_long}</h1>
        <NavBar />
      </div>
      <div className={classes.imgContainer} />
    </header>
  );
};

export default Header;
