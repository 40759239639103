import React, { useState, useContext, Fragment, useEffect, useRef } from "react";
import InfoModal from "../../general/InfoModal";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import classes from "./Inputs.module.css";
import {startOfDay, addNYears} from '../../../shared/utilities/Formatters'

const Input = (props) => {
  
  
  //function that handles wheel event over the input so that the scroll position on the page changes, but the input
  //value does not change
  const onWheel = (e) => {
    e.preventDefault();
    document.documentElement.scrollTop += e.deltaY
  }
  const inputRef = useRef(null);


  //function to deal with a user hitting backspace on a `percent` input - we want this to delete the
  //character before the '%', not the '%' itself.
  function processPercentDelete(x, y) {
    // Check if y is the same as x with a '%' appended
    if (y === x + '%') {
        // Return y with its penultimate character removed
        return y.slice(0, -2) + y.slice(-1);
    }
    // If the condition is not met, return x
    return x;
}


function sanitisePercentString(str) {
  // 1. Remove any character that is not an arabic numeral, a decimal point, or percentage
  let cleaned = str.replace(/[^0-9.%.]/g, '');

  // 2. Handle decimal points
  if (cleaned.charAt(0) === '.') { // Remove if the first character is a decimal point
      cleaned = cleaned.substring(1);
  }

  let firstDecimalPointIndex = cleaned.indexOf('.');
  if (firstDecimalPointIndex !== -1) {
      // Remove any subsequent decimal points
      cleaned = cleaned.substring(0, firstDecimalPointIndex + 1) +
                cleaned.substring(firstDecimalPointIndex + 1).replace(/\./g, '');
  }

  // 3. Handle percentage signs
  if (cleaned.charAt(cleaned.length - 1) === '%') {
      cleaned = cleaned.substring(0, cleaned.length - 1).replace(/%/g, '') + '%';
  } else {
      cleaned = cleaned.replace(/%/g, '');
  }

  // 4. Ensure no more than two characters after the decimal point
  firstDecimalPointIndex = cleaned.indexOf('.');
  if (firstDecimalPointIndex !== -1 && (firstDecimalPointIndex + 3) < cleaned.length) {
      cleaned = cleaned.substring(0, firstDecimalPointIndex + 3) +
                (cleaned.endsWith('%') ? '%' : '');
  }

  return cleaned;
}


	function formatToPoundNoRounding(number) {
    if(String(number).endsWith('.')) {
      var suffix = '.'
    } else {
      if(String(number).endsWith('.0')) {
        var suffix = '.0'
      } else {
        var suffix = ''
      }
    }

		const ret = new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'GBP',
			minimumFractionDigits: 0
		}).format(number);

    
		if(isNaN(number) || String(number) === '£0' || String(number) === '0') {
 
			return '£0'
		} else {
			return ret + suffix
		}
	}
	

  function poundToNumber(formattedString) {

    const formattedStringType = String(formattedString)
    if(formattedStringType === '' || formattedStringType === '£' || formattedStringType === '£0' || formattedStringType === '0') {

      return '0'
    }

    const cleanedString = formattedStringType.replace(/[^0-9.-]+/g, "");
    
    if (cleanedString.endsWith('.') || cleanedString.endsWith('.0')) {

        return cleanedString;  // Return the string as-is if it ends with a dot
    }

    return parseFloat(cleanedString);
}


  
  useEffect(
    () => {
      const inputNode = inputRef.current;
    if (inputNode) {
      inputNode.addEventListener('wheel', onWheel, { passive: false });
    }

    return () => {
      if (inputNode) {
        inputNode.removeEventListener('wheel', onWheel);
      }
    }
  },
    []
  )

  const { inputs, setInputs, valid, setValid, max_ret_date, isScrollingRef } = useContext(InputDataContext);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const par_age_never_changed = useRef(false) //use this to keep track of whether the user has ever changed the partial retiremnt age


  const modalHandler = () => {
    setShowModal(!showModal);
  };



  function getEarlierDate(date1, date2) {
    return date1 < date2 ? date1 : date2;
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setShowError(true);
      }, 1000);
    }
    if (!error) {
      setShowError(false);
    }
  }, [error]);

  useEffect(() => {
    if (props.external_error_message !== '') {
      setTimeout(() => {
        setShowError(true);
      }, 1000);
    }
    if (props.external_error_message === '') {
      setShowError(false);
    }
  }, [props.external_error_message]);

  function changeSPA(dob) {
      const address = (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "api/getspa";
      console.log(address)
      const requestOptions = {
        method: "Post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          { 
            DOB : dob,
          }
        )
      }
  
      fetch(address, requestOptions)
      .then((response) => response.json())
      .then(
        (data) => {
          props.SPA.current = data.SPA

          max_ret_date.current = getEarlierDate(addNYears(new Date(data.SPA), 8), addNYears(new Date(), 1)) //we don't have erfs for more than 9 years after SPA.
        //so to project a full retirement year 1 year after pr, we need that FR year to be no more than 9 years after SPA. Therefore the pr date can be no more than
        //8 years after SPA
  
      })
  }

  function handleOtherError(updated_inputs) { //changing the value for a given input can make the value for another input invalid. We deal with this here
   
    if(props.input !== 'djs' && updated_inputs.parAge !== '' && (addNYears(updated_inputs.dob, 55) > startOfDay(new Date(updated_inputs.parAge)) )  ) {
      
      props.set_external_par_date_error_message('You cannot partially retire before age 55')
    } else if(props.input !== 'djs') {
      props.set_external_par_date_error_message('')   
      const par_age = startOfDay(new Date(updated_inputs.parAge))
      if(
        (par_age < max_ret_date.current) && (par_age >=  startOfDay(new Date())) ){
          
        setValid(prev => {return({...prev, parAge : true})})
       
      }
    }

    if(props.input !== 'parAge' && (addNYears(updated_inputs.dob, 16) > startOfDay(new Date(updated_inputs.djs)) ) ) {
      props.set_external_djs_error_message('Your joining date cannot be before your 16th birthday')
    } else if(props.input !== 'parAge') {
      props.set_external_djs_error_message('')
      if(updated_inputs.djs !== '' && startOfDay(new Date(updated_inputs.djs)) < startOfDay(new Date('2012-04-01'))) {
        
        setValid(prev => {return({...prev, djs : true})})
        
      }
    }
  }

  const changeHandler = (event) => {
    if(isScrollingRef.current) {
      return
    }

    
    if(props.inputType === 'select') {
		setInputs({ ...inputs, [props.input]: event.target.value})
	}

    
  if(props.inputType === 'percent') {
    console.log('etv', event.target.value)

    const old_val = inputs[props.input] === '' ? '' : inputs[props.input] + '%'
    console.log('old val', old_val)
    var new_val = processPercentDelete(event.target.value, old_val).replace('%', '')
    new_val = sanitisePercentString(new_val)
    setInputs(
        {...inputs, [props.input] : new_val}
    )
    
    var userInput = Number(new_val.replace('%', ''));
    console.log('userInput', userInput)

    if(userInput > props.maxValue) {
      setError(props.maxValErrorMsg)
      setValid({ ...valid, [props.input]: false });
      return
    }

    if(userInput < props.minValue) {
      setError(props.minValErrorMsg)
      setValid({ ...valid, [props.input]: false });
      return
    }
  }

    if ( ["number", "integer"].includes(props.inputType) ) {
      
      if(props.inputType === 'number') {
	      setInputs({ ...inputs, [props.input]: poundToNumber(event.target.value) });
        var userInput = Number(poundToNumber(event.target.value));
      } 
      
      

      if(props.inputType === 'integer') {
        const val = event.target.value === '' ? '' : Math.round(event.target.value)
        setInputs({ ...inputs, [props.input]: val });
        var userInput = val;
      }


      const minValue = props.minValue;
      const maxValue = props.maxValue;
      
      if (userInput < minValue) {
        setError(props.minValErrorMsg);
        setValid({ ...valid, [props.input]: false });
        return;
      }

      if (userInput > maxValue) {
        setError(props.maxValErrorMsg);
        setValid({ ...valid, [props.input]: false });
        return;
      }

      if(event.target.value === '') {
        setError(
          props.class_inhibitor ? 
          'You need to enter both of these values. You can enter "0" if appropriate' :
          'You need to provide this value. You can enter "0" if appropriate.'
          );
        setValid({ ...valid, [props.input]: false });
        return;
      }
    }

    if (props.inputType === "date") {
	  setInputs({ ...inputs, [props.input]: event.target.value})
      let userInput = startOfDay(new Date(event.target.value)); //if we don't use 'start of day', by default there are a few hours that might get added on, and this could mess up our equality comparisons
      let dateMinValue = startOfDay(new Date(props.minValue));
      let dateMaxValue = startOfDay(new Date(props.maxValue));


      if (userInput < dateMinValue) {
        if(props.input === 'parAge') {
          setError(props.minValErrorMsg(userInput))
          props.set_external_par_date_error_message('')
        } else {
        setError(props.minValErrorMsg);
        }
        setValid({ ...valid, [props.input]: false });
        return;
      }

      if (userInput > dateMaxValue) {
        if(props.input === 'parAge') {
          setError(props.maxValErrorMsg(userInput))
          props.set_external_par_date_error_message('')
        } else {
          setError(props.maxValErrorMsg);
        }
        setValid({ ...valid, [props.input]: false });
        return;
      }

      if(event.target.value === '') {
        setError(null);
        setValid({ ...valid, [props.input]: false });
        return;
      }
    }

    
    setValid({ ...valid, [props.input]: true });
    setError(null);

    if(props.input === 'dob') {
      changeSPA(new Date(event.target.value))
    }
    
    if(['dob', 'parAge', 'djs'].includes(props.input)) {
      
      handleOtherError(
        //we are supplying a dictionary with completely up-to-date inputs.
        //Otherwise, when we access `inputs` inside the `handleOtherError` function,
        //the `setInputs({ ...inputs, [props.input]: event.target.value })` executed above will not have taken 
        //effect yet 
        {...inputs, [props.input] : event.target.value}
      )
    }
  }

  const infoMessage = props.help;

  let options = null;
  if (props.options) {
    options = props.options.map((item) => {
      return (
        <option key={item.value} value={item.value}>
          {item.value}
        </option>
      );
    });
  }

  return (
    <Fragment>
      <div className={
        props.class_inhibitor ? 
        (classes.added_years_input_container + (
          (showError && error && (!valid[props.input])) | props.invalid_added_days
          ? 
          ( ' ' + classes.extra_space) 
          : ''
        )
      ) : 
      classes.input_container 
      }> 
        {showModal && (
          <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
        )}
        <section>
          <p>{props.text}</p>
          {infoMessage && <button onClick={modalHandler}>Help</button>}
        </section>
        {props.inputType === "select" && (
          <select defaultValue={inputs[props.input] ? inputs[props.input] : ''} onChange={changeHandler}>
            <option value="" disabled hidden></option>
            {options}
          </select>
        )}
        {props.inputType === "date" && (
          <input
            defaultValue={
              props.defaultValue && inputs[props.input] === '' ? 
              props.defaultValue :
              (inputs[props.input] ? inputs[props.input] : '1965-01-01')
            }
            type="date"
            onChange={changeHandler}
          />
        )}
        {props.inputType === "number" && (
          <input
            //defaultValue={inputs[props.input]}
            value = {inputs[props.input] ? formatToPoundNoRounding(inputs[props.input]) : ""}
            type="text"
            onChange={changeHandler}
            ref = {inputRef}
          />
        )}
        {props.inputType === "integer" && (
          <input
            value = {(inputs[props.input] === 0 || inputs[props.input] ) ? inputs[props.input] : ''}
            type="number"
            onChange={changeHandler}
            min = {props.minValue}
            max = {props.maxValue}

            ref = {inputRef}
          />
        )}
        {props.inputType === "percent" && (
          <input
            value = {(inputs[props.input] === 0 || inputs[props.input] ) ? inputs[props.input] + '%' : ''}
            type="text"
            onChange={changeHandler}


            ref = {inputRef}
          />
        )}

        {showError && error && (!valid[props.input]) &&  <p className={classes.ErrorMsg}>{error}</p>}
        {props.external_error_message !== undefined && props.external_error_message !== '' && showError &&
        
        <p className={classes.ErrorMsg}>{props.external_error_message}</p>}
      </div>
      
    </Fragment>
  );
};

export default Input;
