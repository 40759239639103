import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import Input from "../components/yourdetails/inputs/Input";
import PartTimeServiceInput from "../components/yourdetails/inputs/PartTimeServiceInput";

import { InputDataContext } from "../shared/contexts/InputDataContext";

import {formatDate, startOfDay, addNYears} from "../shared/utilities/Formatters"

import CalculateButton from "../components/yourdetails/CalculateButton";
import classes from "./2-YourDetails.module.css";

import FancySlider from '../components/results/switches/FancySlider'
import InfoModal from "../components/results/switches/InfoModal";



function YourDetailsPage() {
  
  const { inputs, setInputs, valid, setValid, from_backend, show_salary_warning_ref, max_ret_date, isScrollingRef} = useContext(InputDataContext);
  const [safe_percentage, set_safe_percentage] = useState('')
  const [show_added_years_modal, set_show_added_years_modal] = useState(false)
  const [trigger_api, set_trigger_api] = useState(false)
  const [rerender_calculate_button, set_rerender_calculate_button] = useState(false)
  const [external_par_date_error_message, set_external_par_date_error_message] = useState('') 
  const [external_djs_error_message, set_external_djs_error_message] = useState('')
  const SPA = useRef(null)
  const apiCallTimer = useRef(null);
  const [invalid_added_days, set_invalid_added_days] = useState(false)
  const [show_salary_warning, set_show_salary_warning] = useState(false)
  const scrollTimeout = useRef(null)

  function dateToString(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }

  const calculateWorkingPercentage = (partTimeService, retDate) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Helper to calculate days between dates, inclusive of start date
    const daysBetween = (startDate, endDate) => {
      return Math.max(
        (new Date(endDate) - new Date(startDate)) / MS_PER_DAY + 1,
        0
      );
    };

    // Get the start of the 12 month period
    let startOfPeriod = new Date(retDate);
    startOfPeriod.setFullYear(startOfPeriod.getFullYear() - 1);
    startOfPeriod.setDate(startOfPeriod.getDate() + 1);

    let totalDays = daysBetween(startOfPeriod, retDate);
    let daysNotWorked = 0;

    partTimeService
      .filter(
        (service) =>
          new Date(service.to) >= startOfPeriod &&
          new Date(service.from) <= new Date(retDate)
      )
      .forEach((service) => {
        let from =
          new Date(service.from) < startOfPeriod
            ? startOfPeriod
            : new Date(service.from);
        let to =
          new Date(service.to) > retDate ? retDate : new Date(service.to);

        let daysInService = daysBetween(from, to);
        let partTimeDaysNotWorked =
          daysInService * (1 - Number(service.proportion) / 100);

        daysNotWorked += partTimeDaysNotWorked;
      });

    return (1 - daysNotWorked / totalDays) * 100;
  };

  const max_dob = addNYears(new Date(), -54) //we need their prd to be within one year of today. If they are < 54, this would entail retiremnet pre-age 55.

  const min_dob = startOfDay(new Date('1953-06-01'));// would have an SPA of about 65, making their SPA date about 2019, meaning that we can 
  //project full retirement as late as 2027 without going more than 9 years beyond their SPA. The key here is that we only have LRF's for CARE up to 9 years
  //after the SPA
  

  // if(inputs.dob, inputs.schemeName) {
  //   var max_djs = startOfDay(new Date('2012-03-31')) // if joined after this date, not eligible for remedy
  //   var min_djs;
  //   if (inputs.schemeName === "Mental Health Officer") {
  //       min_djs = Date('1995-03-06'); }
  //     else {
  //       min_djs = addNYears(inputs.dob, 16)// can't have joined before they were 16 years old.
  //     }
  //   } 

    if (inputs.dob && inputs.schemeName) {
      var min_djs = addNYears(inputs.dob, 16); // can't have joined before they were 16 years old.
      var max_djs;
    
      if (inputs.schemeName === "Mental Health Officer" | inputs.schemeName === "Special Class Officer" ) {
        max_djs = startOfDay(new Date('1995-03-05')); }
      else {
        max_djs = startOfDay(new Date('2012-03-31')) // if joined after this date, not eligible for remedy
      }
    }
    

useEffect(() => {

  //this useEffect ensures that the backend gets called when inputs are updated or when trigger_backend changes.
  //But we use the setTimeout function to wait 150ms after one of them has changed - that way, we don't make multiple calls
  //to the backend if, for example, the user is typing a long number (where the inputs would change with every character typed)
  if (apiCallTimer.current) {
    clearTimeout(apiCallTimer.current);
}

apiCallTimer.current = setTimeout(() => {

  if(valid.schemeName && 
    valid.dob &&
    valid.djs &&
    valid.parAge &&
    valid.CurrentPen &&
    valid['2015Pen'] &&
    valid.AddedYears &&
    valid.AddedPen  &&
    valid.NewSal &&
    valid.postReshapingPT &
    inputs.HasPT !== '') {
    let address = (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "api/safepercentage";

    const requestOptions = {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        { 
          DOB : inputs.dob,
          scheme : inputs.schemeName,
          DJS : inputs.djs, 
          partial_retirement_date : inputs.parAge,
          salary_2015 : inputs['2015Pen'], 
          salary_today : inputs.CurrentPen,
          post_reshaping_salary : inputs.NewSal,
          post_reshaping_pt : inputs.postReshapingPT,
          pt : inputs.partTimeService,
          HasPT : inputs.HasPT
        }
      )
    }

    fetch(address, requestOptions)
    .then((response) => response.json())
    .then(
      (data) => {
        from_backend.current['safe percentage'] = data['safe percentage']
        from_backend.current['true safe percentage'] = data['true safe percentage']
        from_backend.current['all results'] = data['all results']
        from_backend.current['both salaries'] = data['both salaries']
        from_backend.current['prd'] = data['prd']
        from_backend.current['all_ret_dates'] = data['all_ret_dates']
        from_backend.current['all_ret_dates_end'] = data['all_ret_dates_end']
        from_backend.current['both reshaping checks'] = data['both reshaping checks']
        from_backend.current['date aged 55'] = data['date aged 55']
        from_backend.current['date reached 20 years'] = data['date reached 20 years']
        from_backend.current['first year indexes'] = data['first year indexes']
        set_safe_percentage(data['true safe percentage']*100)
        from_backend.current['results populated'] = true
        
      }
      ).then(() => set_rerender_calculate_button(!rerender_calculate_button))
    .catch((error) => console.log(error))


  }
},
450 //end of set timeout; setting delay to 150ms so we don't call backend every time user types.
)
}, [inputs, trigger_api])

useEffect(
  () => {

    if (inputs.HasPT === 'Yes') {
    var pt_over_12_months_to_ret_date = calculateWorkingPercentage(
      inputs.partTimeService, inputs.parAge
    ) / 100;
    } else {
      var pt_over_12_months_to_ret_date = 1
    }

    console.log('pt over 12 months', pt_over_12_months_to_ret_date)
    
    if(
      ((Number(inputs.postReshapingPT)/100 * Number(inputs.NewSal)) / (Number(inputs.CurrentPen) * pt_over_12_months_to_ret_date)) > 0.9 
      && show_salary_warning_ref.current
      ) {
      set_show_salary_warning(true)
      show_salary_warning_ref.current = false
    }

  }, [inputs.postReshapingPT, inputs.NewSal, inputs.CurrentPen, inputs.partTimeService, inputs.parAge, inputs.HasPT]
)

useEffect(
  () => {
   
    if(inputs.AddedDays === '' | inputs.AddedDays > 365 | inputs.AddedDays < 0) {
      
      set_invalid_added_days(true)
    } else {
      set_invalid_added_days(false)
    }
  }, 
  [inputs.AddedDays]
)

//detect whether user is scrolling
useEffect(() => {
  
  const handleScroll = () => {
    
    isScrollingRef.current = true;
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      isScrollingRef.current = false;
    }, 150); // 150ms after the user stops scrolling, set isScrolling to false
  };

  document.addEventListener('scroll', handleScroll);

  return () => {
    document.removeEventListener('scroll', handleScroll);
    if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
  };
}, []);


  return (
    <div id="Your_Details_Page">
      {show_salary_warning &&
       <InfoModal 
        modalHandler = {() => set_show_salary_warning(false)}
        infoMessage = {<Fragment> 
          <h1> Post-reshaping earnings more than 90% of pre-shaping earnings</h1>
          <p> Your post-partial retirement earnings are more than 90% of your pre-partial retirement earnings 
            (after accounting for part-time working), which is not permitted.
             </p>
          <p> Once you close this window, it won't show again. </p>
        </Fragment>}
      />
       }

       { show_added_years_modal &&
        <InfoModal
        modalHandler = {() => set_show_added_years_modal(false)}
        infoMessage = {
          <Fragment>
                <h2>1995 Scheme added years</h2>
                <p>
                Added years were a way of allowing 1995 Section members who could not reach 40 years of Scheme membership by their Normal Pension Age to increase their Scheme benefits by buying additional years of membership. If you are unsure of the number of years and days you have purchased, please contact NHS Pensions via  
                <a href= "apavcreferrals@nhsbsa.nhs.uk"> apavcreferrals@nhsbsa.nhs.uk</a>
                </p>
              </Fragment>
        }
        />
       }
      <h2>About your career</h2>
      <p>
      Answer the following questions to get an estimate of your pension benefits
      </p>
      {false && <button
      
      onClick = {
        () => {
          let new_inputs = { 
            schemeName : 'Mental Health Officer',
            dob : '1965-01-02',
            djs : '1994-01-01',
            parAge : '2024-01-01',
            HasPT : 'Yes', 
            AddedDays : 0,
            CurrentPen : 60000,
            '2015Pen' : 50000,
            AddedYears : 3,
            AddedPen : 1000,
            NewSal : 40000,
            postReshapingPT : 50,
            BenefitWithdrawn : 50,
            take_safe_percentage : 'No',
            partTimeService: [
              {'from' : '2000-05-10', 'to' : '2002-04-20', 'proportion' : 50},
              {'from' : '2014-05-10', 'to' : '2015-01-01', 'proportion' : 30},
              {'from' : '2015-01-02', 'to' : '2015-03-01', 'proportion' : 0},
              {'from' : '2017-01-02', 'to' : '2022-03-01', 'proportion' : 10}
            ]
          }
          setInputs(
            new_inputs
          );
          setValid(currentState => {
            
            const newState = { ...currentState };
            Object.keys(new_inputs).forEach(key => {
              newState[key] = true;
            });
            return newState;
          });
          set_trigger_api(!trigger_api)
        }
      }
      > Auto-fill</button>}
      <Input

        input={"schemeName"}
        inputType={"select"}
        options={[{ value: "Special Class Officer" }, { value: "Mental Health Officer" }, { value: "Neither of these" }]}
        text={"Let us know if you have Mental Health Officer or Special Class Status:"}
        help={null}

        />
         
        
        {valid.schemeName && ( 
        <Input
          input={"dob"}
          inputType={"date"}
          text={`What is your date of birth?`}
          minValErrorMsg={
            "Your date of birth cannot be before June 1953 because this illustrator cannot account for some of the special circumstances that would be applicable."
          }
          maxValErrorMsg={ "Your cannot use this illustrator if you are less than 54 years old."}
          help={null}
          minValue = {min_dob}
          maxValue = {max_dob}
          SPA = {SPA}
          max_ret_date = {max_ret_date}
          set_external_djs_error_message = {set_external_djs_error_message}
          set_external_par_date_error_message = {set_external_par_date_error_message}

        />  ) } 

        {valid.schemeName && 
        valid.dob ?
        (
        <Input
          input={"djs"}
          inputType={"date"}
          text={`What date did you join the NHS Pension Scheme?`}
          defaultValue = {'2000-01-01'}
          minValErrorMsg={
            "Your joining date can't be less than 16 years after your birthday"
          }

          // maxValErrorMsg={ "Your joining date can't be after 1st April 2012 - if you joined after this date, you are not eligible for the McCloud remedy"}

          maxValErrorMsg={
            inputs.schemeName === 'Mental Health Officer' | inputs.schemeName === 'Special Class Officer'
              ? "We would expect MHO and Special Class members to have joined before 6 March 1995, as such please check you have entered the correct date of joining and if so, then please change your status to ‘Neither of these’ in order to progress further."
              : "Your joining date can't be after 1st April 2012 - if you joined after this date, you are not eligible for the McCloud remedy"
          }
          help={
            <Fragment> 
              <h1> Joining date</h1>
              <p> This should be the date you joined the scheme.</p>
              <p> If you left and then joined again at a later date, put the earliest joining date.</p>
              <p> Then, for any periods of time during which you were not part of the scheme, you can enter a 
                part-time percentage of 0% in the question about part-time working that will appear below.
              </p>
            </Fragment>
          }
          minValue = {min_djs}
          maxValue = {max_djs} 
          external_error_message = {external_djs_error_message}
          set_external_djs_error_message = {set_external_djs_error_message}
        />  )  : null}

      {valid.schemeName && 
      valid.dob &&
      valid.djs &&
      external_djs_error_message === '' ? ( 
        <Input
          input={"parAge"}
          inputType={"date"}
          defaultValue = {dateToString(new Date())}
          text={"What date would you like to take partial retirement?"}
          maxValErrorMsg={(etv) => 
            {return(startOfDay(new Date(etv)) > addNYears(new Date(), 1) ? 
            "Your partial retirement cannot be more than 1 year after today." :
            ""
      )}
          }
          minValErrorMsg={(etv) => {
            return(startOfDay(new Date(etv)) < startOfDay(new Date()) ? 
            "Your partial retirement date has to be today or in the future." :
            "You cannot partially retire before age 55"
      )
    
          }
        }
          maxValue = {max_ret_date.current}
          minValue = {Math.max(startOfDay(new Date()), addNYears(inputs.dob, 55))} // later of today or age member turns 55
          external_error_message = {external_par_date_error_message}
          set_external_par_date_error_message = {set_external_par_date_error_message}
          />
        ) : null}

      
      {valid.schemeName && 
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === ''  ? (
        <Input
        input={"CurrentPen"}
        inputType={"number"}
        text={"What are your current full-time equivalent, pensionable earnings?"}
        help={
          <Fragment>
            <h2>Pensionable earnings at current date</h2>
            <p>
            This should be pensionable earnings and should be the Full-Time Equivalent amount. For example, if you work three days per week and earn £15,000 pa for those three days, then your FTE would be what you would earn if you worked five days, in this case £25,000. Please note that final pensionable pay used in this illustrator is based on gross pay at the point of retirement which differs from the actual final pensionable pay definitions that will be used at retirement.
            </p>
          </Fragment>
        }
        minValue={1}
        maxValue={200000}
        minValErrorMsg={"The pensionable earnings you've entered is too low."}
        maxValErrorMsg={"The pensionable earnings you've entered is too high."}

        />
      ) : null}

      {valid.schemeName && 
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
      valid.CurrentPen ? (
        <Input
        input={"2015Pen"}
        inputType={"number"}
        text={"What were your full-time equivalent pensionable earnings in April 2015? - Enter 0 if you don’t know."}
        help={
          <Fragment>
            <h2>Pensionable earnings as at April 2015 </h2>
            <p>
            This should be your pensionable earnings and it should be the full-time equivalent amount. If you don’t know what your salary was on 1 April 2015, enter ‘0’ and the calculator will use assumptions to estimate your salary on this date. 
            </p>
          </Fragment>
        }
        minValue={0}
        maxValue={200000}
        minValErrorMsg={"The 2015 salary you've entered is too low."}
        maxValErrorMsg={"The 2015 salary you've entered is too high."}
      
        />
      ) : null}

      {valid.schemeName && 
        valid.dob &&
        valid.djs &&
        valid.parAge &&
        external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
        valid.CurrentPen &&
        valid['2015Pen'] ? (
          <div className ={classes.input_container + ' ' + classes.added_years_container}> 
            <p>How many 1995 Section added years have you purchased? </p>
            
            <div style = {{'display' : 'flex'}}> 
            
            <Input
            input={"AddedYears"}
            inputType={"integer"}
            text={"Years"}
            
            minValue = {0}
            maxValue={100}
            maxValErrorMsg={"The added years you've entered is too high."}
            minValErrorMsg={"Your added years cannot be below 0."}
            class_inhibitor = {true}
            invalid_added_days = {invalid_added_days}

            />
          <Input
            input={"AddedDays"}
            inputType={"integer"}
            text={"Days"}
            minValue = {0}
            maxValue={365}
            maxValErrorMsg={"The added days value you've entered is too high."}
            minValErrorMsg={"Your added days cannot be below 0."}
            class_inhibitor = {true}
                    />
            </div>
          <button
          className = {classes.added_years_button}
          onClick = {() => set_show_added_years_modal(true)}
          > Help </button>
          </div>
        ) : null}

      {valid.schemeName && 
        valid.dob &&
        valid.djs &&
        valid.parAge &&
        external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
        valid.CurrentPen &&
        valid['2015Pen'] &&
        valid.AddedYears &&
        valid.AddedDays ? (
        <Input
          input={"AddedPen"}
          inputType={"number"}
          text={"How much 2015 Scheme added pension have you purchased?"}
          help={
            <Fragment>
              <h2>2015 Scheme added pension</h2>
              <p>
              Additional pension is a way of increasing your pension benefits where you can choose to buy a set amount of annual pension and pay either a lump sum or regular contributions from your pay. If you are unsure of the amount of additional pension you have purchased, please contact NHS Pensions via 
              <a href="apavcreferrals@nhsbsa.nhs.uk"> apavcreferrals@nhsbsa.nhs.uk</a>
              </p>
            </Fragment>
                  }
          minValue = {0}
          maxValue={100000}
          maxValErrorMsg={"The added pension you've entered is too high."}
          minValErrorMsg={"Your added pension cannot be below 0."}

          />
      ) : null}




      {valid.schemeName && 
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
      valid.CurrentPen &&
      valid['2015Pen'] &&
      valid.AddedYears &&
      valid.AddedDays &&
      valid.AddedPen ? (
      <Input
      input={"NewSal"}
      inputType={"number"}
      text={"How much will your full time equivalent pensionable salary be, after your job re-shaping for partial retirement?"}
      help={
        <Fragment>
          <h2>New pensionable salary</h2>
          <p>
          This should be your expected pensionable salary after partial retirement. To take partial retirement, members must reduce the annual rate of their pensionable pay 
	        by 10% or more of their pensionable pay received in the previous 12 months.
          </p>
        </Fragment>
              }
              minValue={1}
              maxValue={200000}
              minValErrorMsg={"The figure you have entered is too low."}
              maxValErrorMsg={"The figure you have entered is too high."}

              />
            ) : null}

{valid.schemeName && 
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
      valid.CurrentPen &&
      valid['2015Pen'] &&
      valid.AddedYears &&
      valid.AddedDays  &&
      valid.AddedPen &&
      valid.NewSal ? (
      <Input
      input={"HasPT"}
      inputType={"select"}
      text={"Have you ever worked part time before?"}
      options={[{ value: "Yes" }, { value: "No" }]}
              />
            ) : null}

{valid.schemeName && 
        valid.dob &&
        valid.djs &&
        valid.parAge &&
        valid.CurrentPen &&
        valid['2015Pen'] &&
        valid.AddedYears &&
        valid.AddedDays &&
        valid.AddedPen &&
        valid.NewSal &&
        inputs.HasPT === 'Yes'
        ? (
        <PartTimeServiceInput
        formatDate = {formatDate}
        />
      ) : null}

{valid.schemeName &&  
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
      valid.CurrentPen &&
      valid['2015Pen'] &&
      valid.AddedYears &&
      valid.AddedDays  &&
      valid.AddedPen  &&
      valid.NewSal &&
      inputs.HasPT !== '' 
      ? (
        <Input
        input={"postReshapingPT"}
        inputType={"percent"}
        text={"What will your part time percentage be, after you take partial retirement?" +
        " This value should be between 1% and 100%. It should  reflect the percentage of full time equivalent hours that you will work after partial retirement. For example, if you will work 28 hours per week when the full time equivalent" +
        " is 35 hours per week, your part-time proportion would be 80%, in which case you should write '80%'. "}
        help={
          null
        }
        minValue={1}
        maxValue={100}
        minValErrorMsg={"The proportion you've entered is not in between 1% and 100%"}
        maxValErrorMsg={"The proportion you've entered is not in between 1% and 100%"}
        />
        

      ) : null}


{valid.schemeName && 
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
      valid.CurrentPen &&
      valid['2015Pen'] &&
      valid.AddedYears &&
      valid.AddedDays &&
      valid.AddedPen  &&
      valid.NewSal &&
      valid.postReshapingPT 
      && safe_percentage !== '' ? (
        <Input 
          input = {'take_safe_percentage'}
          inputType = {'select'}
          help = {<Fragment><h1> What is the safe percentage? </h1>
          <p> The safe percentage is the percentage of your pension that you could withdraw at partial retirement below which you can avoid the risk of needing to repay some of the payments you receive at a later date. 

          </p>
          {/*<p> 
          This has been calculated using your actual service up to 31 March 2015 as a proportion of your total service up to 31 March 2022.
          </p>
          */}
          
          <p> If you take more than the “safe percentage”, there is a risk that you may have overpayments to repay when you make your McCloud choice later on.
            </p> 
          <p> 
          You cannot make your McCloud choice yet. However, when you can make your McCloud choice you will be able to revisit the proportion of your benefits you have chosen to take, if this is within the safe percentage.
          </p>

          
          
          </Fragment>}
          options={[{ value: "Yes" }, { value: "No" }]}
          text = {
            <Fragment>
            Would you like to take the ‘safe percentage’ at partial retirement? Using the information that you have provided above, we have calculated the safe percentage as <span style = {{'fontWeight' : '600'}}> {Number(safe_percentage).toFixed(0)}% </span>in your case. If you select ‘no’, you will be able to choose a percentage of your total benefits between 20% and 100% at partial retirement. 
          </Fragment>}
        />
      ) : null}

{valid.schemeName && 
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
      valid.CurrentPen &&
      valid['2015Pen'] &&
      valid.AddedYears &&
      valid.AddedDays &&
      valid.AddedPen  &&
      valid.NewSal &&
      valid.postReshapingPT 
      && inputs.take_safe_percentage === 'No' &&
      <Input 
        input = {'only_take_legacy'}
        inputType = {'select'}
        help = {<Fragment><h1> Only taking legacy benefits at partial retirement </h1>
         <p> 
          If you select 'yes' for this option, we will assume that if you pick the 'choose 1995 Section' option for the McCloud remedy,
          you will draw down on all of your legacy scheme benefits at partial retirement and none of your 2015 Scheme benefits.
         </p>
         <p> 
          And when modelling your pension under the 'choose 2015 Scheme' option for the McCloud remedy, we will set the percentage benefits
          to be withdrawn at partial retirement to be the same percentage as in the 'choose 1995 Section' scenario. However, since you will have less legacy
          pension and more CARE pension available in the 'choose 2015 Scheme' scenario, this means that all of your legacy pension and some of your CARE pension
          will be drawn down at partial retirement in the 'choose 2015 Scheme' scenario. 
         </p>

         
         
         </Fragment>}
        options={[{ value: "Yes" }, { value: "No" }]}
        text = {"Would you like to take all of your 1995 section benefits and none of your 2015 Scheme benefits at partial retirement?"} 
        />
}



      {valid.schemeName && 
      valid.dob &&
      valid.djs &&
      valid.parAge &&
      external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
      valid.CurrentPen &&
      valid['2015Pen'] &&
      valid.AddedYears &&
      valid.AddedDays &&
      valid.AddedPen  &&
      valid.NewSal &&
      valid.postReshapingPT 
      && inputs.take_safe_percentage === 'No' &&
      inputs.only_take_legacy === 'No' ? (
        <FancySlider 
        value = {inputs.BenefitWithdrawn}
        setValue = {(x) => setInputs({...inputs, BenefitWithdrawn: x}) }
        safe_percentage = {Number(safe_percentage).toFixed(1)}
        />
      ) : null}


    




{valid.schemeName && 
        valid.dob &&
        valid.djs &&
        valid.parAge &&
        external_djs_error_message === '' &&
      external_par_date_error_message === '' &&
        valid.CurrentPen &&
        valid['2015Pen'] &&
        valid.AddedYears &&
        valid.AddedDays &&
        valid.AddedPen &&
        valid.NewSal &&
        inputs.take_safe_percentage !== '' &&
        safe_percentage !== ''  &&
        (valid.only_take_legacy | inputs.take_safe_percentage === 'Yes') ? (
        <div className={classes.button_container}>
          <CalculateButton rerender_calculate_button = {rerender_calculate_button} safe_percentage = {safe_percentage}/>
        </div>
      ) : null}

<div style = {{'marginBottom' : '200px'}}> </div>
    </div> 
  );
}


export default YourDetailsPage;
