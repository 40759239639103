import React, { createContext, useState, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/0-Layout";
import HomePage from "./pages/1-Home";
import YourDetailsPage from "./pages/2-YourDetails";
import ResultsPage from "./pages/3-Results";
import TACPage from "./pages/4-TAC";
import params from "./shared/utilities/global_parameters";
import { InputDataContext } from "./shared/contexts/InputDataContext";

export const OutputDataContext = createContext({});

function App() {
  Object.freeze(params); /* make it impossible to modify parameters' */
  const [inputs, setInputs] = useState({
    inReceipt: null,
    //legacyScheme: null,
    djs: '',
    dob: null,
    npa: null,
    salaryCurrent: null,
    partTimeService: [],
    salaryIncrease: 0,
    ['commutation percentage'] : 'None', 
    'BenefitWithdrawn' : 20, 
    'parAge' : '', 
    'HasPT' : '',
    take_safe_percentage : ''
  });

  const from_backend = useRef({})

  const [valid, setValid] = useState({
    inReceipt: false,
    //legacyScheme: false,
    dob: false,
    salaryCurrent: false,
    HasPT: false
  });

  const max_ret_date = useRef(null)
  const isScrollingRef = useRef(false)

  const [outputData, setOutputData] = useState([]);
  const which_backend_trigger = useRef({})
  const show_salary_warning_ref = useRef(true)
  return (
    <InputDataContext.Provider value={{ inputs, setInputs, valid, setValid, from_backend, which_backend_trigger, show_salary_warning_ref, max_ret_date, isScrollingRef }}>
      <OutputDataContext.Provider value={{ outputData, setOutputData }}>
        <Layout>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/yourdetails" element={<YourDetailsPage />} />
            <Route path="/results" element={<ResultsPage />} />
            <Route path="/T&Cs" element={<TACPage />} />
          </Routes>
        </Layout>
      </OutputDataContext.Provider>
    </InputDataContext.Provider>
  );
}

export default App;
