import React, { useState, useLayoutEffect, Fragment } from 'react';
import styles from './FancySlider.module.css';
import InfoModal from './InfoModal'

const FancySlider = (props) => {


    const [cssVars, setCssVars] = useState(null);
    const minValue = 20;
    const maxValue = 100;

    useLayoutEffect(() => {
        const getCSSVariableValue = (variableName) => {
        return getComputedStyle(document.body).getPropertyValue(variableName).trim();
        }

        const body_colours = {
        'main-color': getCSSVariableValue('--main-color'),
        'main-color-light': getCSSVariableValue('--main-color-light'),
        'gad-teal': getCSSVariableValue('--gad-teal'),
        'light-grey': getCSSVariableValue('--light-grey'),
        'full-legacy': getCSSVariableValue('--full-legacy'),
        'full-legacy-light': getCSSVariableValue('--full-legacy-light'),
        'legacy-curtailed': getCSSVariableValue('--legacy-curtailed'),
        'legacy-curtailed-light' : getCSSVariableValue('--legacy-curtailed-light'),
        'CARE': getCSSVariableValue('--CARE'),
        'CARE-light': getCSSVariableValue('--CARE-light')
        };

        setCssVars(body_colours);
    }, []);

  const [manual_value, set_manual_value] = useState(props.value)
  const [input_focus, set_input_focus] = useState(false)
  const [showModal, setShowModal] = useState('');

  const modalHandler = () => {
    setShowModal('');
  };

  const infoMessage = <Fragment>
                        <h2> Percentage of benefits taken </h2>
                        <p>
                        You’ll be able to take from 20% to 100% of all your pension benefits in one or two drawdown payments, without having to leave your current job. If you have benefits in both the 1995/2008 Section and 2015 Scheme, the percentage of benefits you apply to take will be taken from your 1995/2008 Section benefits first. Then they’ll be taken from your 2015 Scheme benefits.
                        </p>
                        <p> 
                        If you take more than the “safe percentage”, there is a risk that you may have overpayments to repay when you make your McCloud choice later on.
                        </p>
                        </Fragment>

  const infoMessageSafePerc = <Fragment>
                              <h2> What is the safe percentage? </h2>
                              
                              <p> 
                                The safe percentage is the percentage of your pension that you could withdraw at partial retirement below which you can avoid the risk of needing to repay some of the payments you receive at a later date.
                                </p>
                                <p>
                                If you take more than the “safe percentage”, there is a risk that you may have overpayments to repay when you make your McCloud choice later on.
                              </p>
                              </Fragment>

    // If cssVars is not yet populated, don't render the main content.
    if (!cssVars) {
        return null;  // Or some loading indicator if you wish.
      }


const safe_percentage = props.safe_percentage
const color = ((Number(props.value) <= Number(safe_percentage)) || true) ? cssVars['main-color'] : 'red'

  


  return (
    <div 
    className = {styles.input_container + ' ' + 
    ((Number(props.value) <= Number(safe_percentage) || true) ? styles.safe : styles.danger)
        }
    style = {{'display' : 'flex', 'flexDirection' : 'column', 'alignItems' : 'center'}}>
        {showModal === 'general' && (
          <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
        )}
        {showModal === 'safe_perc' && (
          <InfoModal modalHandler={modalHandler} infoMessage={infoMessageSafePerc} />
        )}
    <section> 
    <p> What percentage of your total benefits would you like to take (between 20% and 100%)? </p>
    <button onClick = {() => setShowModal('general')}> Help </button>
    </section>
    <div className = {styles.slider_wrapper_wrapper} style = {{'marginBottom' : ((Number(props.value) <= Number(safe_percentage)) && false ? '250px' : '55px')}}> 
    <div className={styles.sliderWrapper} 
    style={{'--slider-color': color, '--slider-value' : props.value}}>
        <input type = 'text' 
        style = {{
            'position' : 'absolute', 'top' : '-70px', left: String(((props.value-20)/80)*300 + 5 - 150/2) + 'px',
            'width' : '150px'
        }}
        className = {styles.manual_input}
        value = {input_focus ? manual_value : String(manual_value) + '%'}
        onChange = {
            (e) => {
                if(isNaN(Number(e.target.value))) {
                    return
                }
                set_manual_value(e.target.value)
                if(Number(e.target.value) >= 20 && Number(e.target.value) <= 100) {
                    props.setValue(e.target.value)
                }
            }
        }
        onBlur = {() => 
            {set_input_focus(false)
            if(manual_value !== props.value) {
              set_manual_value(props.value)
            }
            }
          }
        onFocus = {
            () => set_input_focus(true)
        }
        />{false &&
        <span
        className = {styles['safe_perc_warning']}
        style = {{'left' : String(((safe_percentage-20)/80)*300 + 5 - 150/2) + 'px', 'fontSize' : '18px' }}
        > safe percentage: {safe_percentage}% </span>}
      <div className={styles.sliderTrack}>  
        <input 
          className={styles.sliderInput}
          type="range"
          min={minValue}
          max={maxValue}
          value={props.value}
          onChange={(e) => {
   
            props.setValue(e.target.value)
            set_manual_value(e.target.value)
        
        }
        }
        /> 
        
        <div className={styles.sliderThumb}></div>
      </div>
    </div>
    
    </div>
    {Number(props.value) > Number(safe_percentage) && false && <div
    className = {styles.safe_percentage_lower_warning}
    > <p>Warning: safe percentage exceeded </p>
    <button className = {styles.what_mean} onClick = {() => setShowModal('safe_perc')}> What does this mean? </button>
     </div>}
    </div>
  );
};

export default FancySlider;
